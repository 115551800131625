@tailwind base;
/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #CDCDCD;
  }
  
  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #CDCDCD;
    border-radius: 5px;
    border: 5px solid var(--primary);
  }
@tailwind components;
@tailwind utilities;